import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "../assets/css/banner.css";
import Button from '@material-ui/core/Button';


function close(ele){
    //console.log(ele);
    ele.parentNode.remove(ele);
}

class Banner extends React.Component{

    // constructor(props) {
    //     this.close = this.close.bind(this);
    // }
    
    close(ele) {
        // console.log("test");
        ele.target.parentNode.remove(ele.target);
    }

    render () {
        return(
            <div className="homepage-announcement">
                <div>{this.props.strapiHomepage.Banner}</div>
                {/* <span onClick={ this.close.bind(this)}></span>   */}
                <Button onClick={ this.close.bind(this)} className="banner-button" style={{textTransform: 'lowercase'}} variant="outlined" type="submit">Ok, got it!</Button>
            </div>
        )
    }
};

export default () => ( 
<StaticQuery
    query={graphql`
        query {   
            strapiHomepage  {                                    
                WelcomeImage {
                    url
                }
                WelcomeMessage                
                hero{
                    title
                }
                Banner
            }     
        }  
    `}
    render={(data) => (
        <Banner strapiHomepage={data.strapiHomepage} />
    )}
  />
)

//export default Banner;
