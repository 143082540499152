import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Banner from "../components/banner";
import "../assets/css/main.css";
import DOMPurify from "dompurify"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import "../assets/css/homepage.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IndexPage = () => {
  const data = useStaticQuery(query);
  //console.log(data);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  function removeBanner(ele){
    ele.parentElement.remove();
  }

  //const homepageImage = getImage(data.strapiHomepage.seo.WelcomeImage);

  return (
    <div>
      {/* {data.strapiHomepage.Banner &&
        // <div className="homepage-announcement">
        //   {data.strapiHomepage.seo.WelcomeMessage}       
        // </div>
        <Banner></Banner>
      }       */}
      <br/>
      <Layout seo={data.strapiHomepage.seo}>
        <div className="uk-section">
          <div className="uk-container uk-container-large">          
            <div className="homepage-container">
              <div className="logo-container">
                {/* <img className="homepage-welcomeimage" src={data.strapiHomepage.seo.WelcomeImage.publicURL} /> */}
                <img className="homepage-welcomeimage" src={data.strapiHomepage.WelcomeImage.url} alt="" />
              </div>              
              {/* <label>{data.strapiHomepage.TestField}</label> */}
              <label dangerouslySetInnerHTML={{ __html: data.strapiHomepage.WelcomeMessage}}></label>
              {/* <label>{data.strapiHomepage.seo.WelcomeMessage}</label> */}
            </div>
            {/* Example using html from strapi <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.strapiTest.TestField)}} /> */}
          </div>
        </div>
      </Layout>
    </div>
  );
};

const query = graphql`
  query {   
    strapiHomepage  {
        Banner                     
        WelcomeImage {
          url
        }
        WelcomeMessage
        
        hero{
          title
        }
      }
    }  
`;

export default IndexPage;
